import { Component, OnInit, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { MenuItem } from "../models/MenuItem";

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.css']
})
export class PopupMenuComponent implements OnInit {
  menuItems:MenuItem[];
  menuAnimationClass:string;
  constructor(
    http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    http.get<MenuItem[]>(baseUrl + "api/SiteData/MenuItems").subscribe(result => {
      this.menuItems = result;
    }, error => console.error(error));
  }

  ngOnInit() {
  }

  expandMenu()
  {
    //window.alert("Expanded");
    if (this.menuAnimationClass == "expanding")
    {
      this.menuAnimationClass = "collapsing";
    }
    else
    {
      this.menuAnimationClass = "expanding";
    }
  }

  getMenuItemAnimationClass(increment:number)
  {
    if (this.menuAnimationClass == "expanding")
    {
      return "expandMenuItem" + increment.toString();
    }
    else
    {
      return "expandMenuItem" + increment.toString();
    }
  }
}
