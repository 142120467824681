import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmailMessage, IEmailMessage } from '../models/EmailMessage';
//import { EmailService } from '../email.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  public from:string;
  public subject:string;
  public body: string;
  public contactName: string;
  containerCssClass: string;
  baseUrl: string;
  http: HttpClient;

  constructor(http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
    this.containerCssClass = "yellow-bg jumbotron text-black contact-form-visible";
  }

  ngOnInit() {
  }


  sendEmail()
  {
    //if (this.recipient && this.subject && this.message)
    if (this.contactName != "" && this.from != "" && this.subject != "" && this.body != "")
    {
      //this.from = "ryandailey21@hotmail.com";
      //this.from = "blank@blank.com";
      //this.subject = "Testing Mailgun";
      //this.message = "This is me testing mailgun.";

      console.log("Set the properties");

      var resultMsg: string;
      var emailMessage: IEmailMessage;
      emailMessage = new EmailMessage();
      emailMessage.from = this.from;
      emailMessage.subject = this.subject;
      emailMessage.body = this.body;
      console.log("Applied values to EmailMessage");

      console.log("This is http");
      console.log(this.http);

      var arrMsg: IEmailMessage[];
      arrMsg = [emailMessage];
      var jsonMsg: string = JSON.stringify(arrMsg);
      /*this.http.post<IEmailMessage[]>("api/Email/SendEmail", arrMsg).subscribe(
        result => { console.log(result); }, error => { console.log("Error: " + error.message);}
        );*/

      var headers: HttpHeaders = new HttpHeaders();
      //headers.append('Content-Type', 'application/json; charset=utf-8');
      //headers.append('Content-Type', 'application/x-www-form-urlencoded');

      this.http.post("api/email", { contactName: this.contactName, from: this.from, subject: this.subject, body: this.body }, { headers, responseType: 'text' }).subscribe(
        result => { console.log(result); }, error => { console.log("Error: " + error.message); }
      );
      
      console.log("Email sent");

      this.contactName = "";
      this.from = "";
      this.subject = "";
      this.body = "";

      this.containerCssClass = "yellow-bg jumbotron text-black contact-form-collapse";
    }
  }

  blankEvent()
  {
    console.log("This is a blank event");
  }
}
