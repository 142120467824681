import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
/*
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
*/

import { TopBarComponent } from './top-bar/top-bar.component';
import { AppLinksComponent } from './app-links/app-links.component';
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { HomeComponent } from './home/home.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { IntroTextComponent } from './intro-text/intro-text.component';
import { JoinMailingListComponent } from './join-mailing-list/join-mailing-list.component';
import { SocialMediaComponent } from './social-media/social-media.component';

@NgModule({
  declarations: [
    AppComponent,
    /*NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,*/
    TopBarComponent,
    AppLinksComponent,
    PopupMenuComponent,
    HomeComponent,
    CarouselComponent,
    ContactFormComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    IntroTextComponent,
    JoinMailingListComponent,
    SocialMediaComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,/*,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
    ])*/
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'privacy-policy', component: PrivacyPolicyComponent }
    ])

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
