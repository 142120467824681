import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MailingListMember, IMailingListMember } from '../models/MailingListMember';

@Component({
  selector: 'app-join-mailing-list',
  templateUrl: './join-mailing-list.component.html',
  styleUrls: ['./join-mailing-list.component.css']
})
export class JoinMailingListComponent implements OnInit {

  public address:string;
  public name:string;
  containerCssClass: string;
  baseUrl: string;
  http: HttpClient;

  constructor(http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.http = http;
    this.containerCssClass = "yellow-bg jumbotron text-black mailing-list-form-visible";
  }

  ngOnInit() {
  }


  joinMailingList() {
    if (this.address != "" && this.name != "") {


      var headers: HttpHeaders = new HttpHeaders();
      //headers.append('Content-Type', 'application/json; charset=utf-8');
      //headers.append('Content-Type', 'application/x-www-form-urlencoded');

      this.http.post("api/joinMailingList", { address: this.address, name: this.name }, { headers, responseType: 'text' }).subscribe(
        result => { console.log(result); }, error => { console.log("Error: " + error.message); }
      );
      
      console.log("Added to email list");

      this.address = "";
      this.name = "";

      this.containerCssClass = "yellow-bg jumbotron text-black mailing-list-form-collapse";
    }
  }

}
