import { Component, OnInit, Inject } from '@angular/core';
import { Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CarouselItem } from '../models/CarouselItem';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @Input() carouselDataPath:string;
  @Input() carouselID:string;
  carouselItems:CarouselItem[];
  constructor(
    http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
      console.log(baseUrl + "api/SiteData/CarouselItems");
    http.get<CarouselItem[]>(baseUrl + "api/SiteData/CarouselItems").subscribe(result => {
      this.carouselItems = result;
      console.log(this.carouselItems);
    }, error => console.error(error));
  }

  ngOnInit() {

  }

  getActiveCSSClass(active:boolean)
  {
    if (active)
    {
      return "active";
    }
    else
    {
      return "";
    }
  }
}
