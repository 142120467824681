export class EmailMessage implements IEmailMessage {
  from: string;
  subject: string;
  body: string;
}

export interface IEmailMessage {
  from: string;
  subject: string;
  body: string;
}
