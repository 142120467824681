import { Component, OnInit, Inject} from '@angular/core';
import { Input } from '@angular/core';
import { HostListener } from '@angular/core';
//import { Observable } from "../../../node_modules/rxjs"
//import { HttpGetterService } from '../http-getter.service';
import { HttpClient } from '@angular/common/http';
import { MenuItem } from "../models/MenuItem";
import { ViewChild, ElementRef } from '@angular/core';
//import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';

interface IMenuItem {
  text: string;
  url: string;
  active: boolean;
}

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})

export class TopBarComponent implements OnInit {
  compId: string;
  @Input() menuItemsDataPath:string;
  @Input() name: string;
  menuButton;
  @ViewChild('expandButton', { static: false} ) expandButton: ElementRef;
  http: HttpClient;
  baseUrl: string;
  navBarOrigCssClass:string;
  navBarExpandCssClass:string;
  navBarAnimCssClass:string;
  topBarVisibleCSSClass:string;
  @HostListener('window:scroll', ['$event'])
  onScrollEvent(event)
  {
    if (event.target.scrollingElement.scrollTop >= 200)
    {
      //if(!this.navBar.className.endsWith("top-bar-expanding") && !this.navBar.className.endsWith("top-bar-expanded"))
      if(this.navBarExpandCssClass != "top-bar-expanding" && this.navBarExpandCssClass != "top-bar-expanded")
      {
        //this.navBar.className = this.navBarOrigCssClass + " top-bar-expanding";
        this.navBarExpandCssClass = "top-bar-expanding";
        console.log("expanding");
        //console.log("aria-expanded: " + this.expandButton.getAttribute("aria-expanded"));
        setTimeout(() => {
          //this.navBar.className = this.navBarOrigCssClass + " top-bar-expanded";
          this.navBarExpandCssClass = "top-bar-expanded";
          console.log("expanded");
        }, 1000);
      }
      //this.navBarAnimCssClass = "top-bar-expanding";
    }
    else
    {
      //this.navBar.className = this.navBarOrigCssClass + " top-bar-hidden";

      if(this.navBarExpandCssClass != "top-bar-collapsing" && this.navBarExpandCssClass != "top-bar-collapsed")
      {
        console.log("setting to top-bar-collapsing");
        this.navBarExpandCssClass = "top-bar-collapsing";
        if (this.expandButton.nativeElement.getAttribute("aria-expanded") == "true")
        {
          this.menuButton.click();
        }
        setTimeout(() => {
          this.navBarExpandCssClass = "top-bar-collapsed";
      }, 1000);
        //this.navBarAnimCssClass = "top-bar-collapsing";
      }
      else
      {
        console.log("Already collapsing or collapsed");
        //this.navBar.className = this.navBarOrigCssClass;
      }
    }
  }
  menuItems:MenuItem[];
  constructor(
    http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;

    console.log("Init");
    this.http.get<MenuItem[]>(this.baseUrl + "api/SiteData/MenuItems").subscribe(result => {
      this.menuItems = result;
      //this.navBar = document.getElementById(this.compId + "topNavBar");
      this.menuButton = document.getElementById(this.compId + "menuButton");
      //this.navBarOrigCssClass = this.navBar.className.replace(" top-bar-collapsed", "");
    }, error => console.error(error));
  }

  setNavBarToCollapsed()
  {
    this.navBarExpandCssClass = "top-bar-collapsed";
  }

  setNavBarToExpanded()
  {
    this.navBarExpandCssClass = "top-bar-expanded";
  }

  ngOnInit() {
    
  }


  getActiveCSSClass(active:boolean)
  {
    if (active)
    {
      return "active";
    }
    else
    {
      return "";
    }
  }

  scrollCheck()
  {
    /*//jQuery(window).scroll(function(){
      var fromTopPx = 200; // distance to trigger
      alert("Scrolling reached");
      var scrolledFromtop = jQuery(window).scrollTop();
      if(scrolledFromtop > fromTopPx){
  
        jQuery('.navbar').addClass('scrolled');
      }else{
        jQuery('.navbar').removeClass('scrolled');
      }
    //});*/
  }

}
